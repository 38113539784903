import { FC } from 'react'
import Carousel from 'react-multi-carousel'
import DOMPurify from 'isomorphic-dompurify'
import 'react-multi-carousel/lib/styles.css'
import parse from 'html-react-parser'

import CustomDot from '../CustomDot'
import FiveStarIcon from './FiveStarIcon'
import { responsiveTestimonialsBreakpoints as responsive } from '../../config/responsiveBreakpoints'

interface Testimonial {
  id?: string
  testimonial?: {
    value?: {
      data?: {
        testimonial?: string
        customerName?: string
        location?: string
      }
    }
  }
}

interface TestimonialsSliderProps {
  testimonials: Testimonial[]
}

const TestimonialsSlider: FC<TestimonialsSliderProps> = ({ testimonials }) => {
  if (!testimonials.length) return null

  return (
    <div className="px-2 pb-8">
      <Carousel
        draggable
        swipeable
        responsive={responsive}
        showDots
        autoPlay
        autoPlaySpeed={3000}
        infinite
        transitionDuration={500}
        renderDotsOutside
        containerClass="testimonials-carousel-container"
        customDot={<CustomDot />}
        dotListClass="testimonials-carousel-dot-list"
        renderButtonGroupOutside={true}
        arrows={false}
        pauseOnHover
      >
        {testimonials.map((item, index) => (
          <div
            key={item?.id || index}
            className="mx-2 bg-gray-100 rounded-[16px] flex flex-col min-h-[480px] p-6 justify-between font-[raleway] text-pl-dark text-lg"
          >
            <FiveStarIcon />

            <div className="mx-2 text-wrap leading-[1.3em] flex flex-col gap-4">
              {item?.testimonial?.value?.data?.testimonial &&
                parse(
                  DOMPurify.sanitize(
                    item?.testimonial?.value?.data?.testimonial
                  )
                )}
            </div>

            <div className="flex flex-col gap-4">
              <div className="py-3 border-solid border-t-2 border-[rgb(220, 228, 234)]"></div>
              <span className="font-bold leading-[1em]">
                {item?.testimonial?.value?.data?.customerName}
              </span>
              <span className="text-[16px] text-[rgb(75,85,99)] leading-[1em]">
                {item?.testimonial?.value?.data?.location}
              </span>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default TestimonialsSlider
