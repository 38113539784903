import { FC } from 'react'

interface FiveStarIconProps {
  width?: number
  height?: number
  fillColor?: string
}

const FiveStarIcon: FC<FiveStarIconProps> = ({
  width = 120,
  height = 25,
  fillColor = '#FFC42D',
}) => {
  const STAR_OPACITY = 0.7
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 120 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.4828 16.8526C17.116 17.1808 15.6891 17.1649 14.3299 16.8063C12.9707 16.4477 11.7217 15.7576 10.6946 14.7979C9.66756 13.8381 8.89457 12.6386 8.44487 11.3068C7.99516 9.97496 7.88276 8.55241 8.11775 7.1665C8.08201 7.20102 8.04428 7.23341 8.00475 7.2635C7.72475 7.4765 7.37475 7.5555 6.67475 7.7135L6.03975 7.8575C3.57975 8.4145 2.34975 8.69251 2.05675 9.63351C1.76475 10.5736 2.60275 11.5546 4.27975 13.5156L4.71375 14.0226C5.18975 14.5796 5.42875 14.8586 5.53575 15.2026C5.64275 15.5476 5.60675 15.9196 5.53475 16.6626L5.46875 17.3396C5.21575 19.9566 5.08875 21.2646 5.85475 21.8456C6.62075 22.4276 7.77275 21.8976 10.0748 20.8366L10.6718 20.5626C11.3258 20.2606 11.6528 20.1106 11.9998 20.1106C12.3468 20.1106 12.6738 20.2606 13.3288 20.5626L13.9238 20.8366C16.2268 21.8966 17.3788 22.4266 18.1438 21.8466C18.9108 21.2646 18.7838 19.9566 18.5308 17.3396L18.4828 16.8526Z"
          fill={fillColor}
        />
        <path
          opacity={STAR_OPACITY}
          d="M9.15319 5.49345L8.82518 6.08145C8.46518 6.72745 8.28519 7.05045 8.00519 7.26345C8.04519 7.23345 8.08219 7.20145 8.11819 7.16645C7.88314 8.55246 7.99553 9.97512 8.44528 11.307C8.89504 12.6389 9.66812 13.8385 10.6953 14.7982C11.7225 15.758 12.9717 16.4482 14.331 16.8067C15.6903 17.1651 17.1173 17.1808 18.4842 16.8524L18.4642 16.6624C18.3932 15.9194 18.3572 15.5474 18.4642 15.2024C18.5712 14.8584 18.8092 14.5794 19.2862 14.0224L19.7202 13.5154C21.3972 11.5554 22.2352 10.5744 21.9422 9.63345C21.6502 8.69245 20.4202 8.41345 17.9602 7.85745L17.3242 7.71345C16.6252 7.55545 16.2752 7.47645 15.9942 7.26345C15.7142 7.05045 15.5342 6.72745 15.1742 6.08145L14.8472 5.49345C13.5802 3.22145 12.9472 2.08545 12.0002 2.08545C11.0532 2.08545 10.4202 3.22145 9.15319 5.49345Z"
          fill={fillColor}
        />
        <path
          d="M42.4832 16.8526C41.1164 17.1808 39.6895 17.1649 38.3303 16.8063C36.9711 16.4477 35.7221 15.7576 34.695 14.7979C33.668 13.8381 32.895 12.6386 32.4453 11.3068C31.9956 9.97496 31.8832 8.55241 32.1182 7.1665C32.0825 7.20102 32.0447 7.23341 32.0052 7.2635C31.7252 7.4765 31.3752 7.5555 30.6752 7.7135L30.0402 7.8575C27.5802 8.4145 26.3502 8.69251 26.0572 9.63351C25.7652 10.5736 26.6032 11.5546 28.2802 13.5156L28.7142 14.0226C29.1902 14.5796 29.4292 14.8586 29.5362 15.2026C29.6432 15.5476 29.6072 15.9196 29.5352 16.6626L29.4692 17.3396C29.2162 19.9566 29.0892 21.2646 29.8552 21.8456C30.6212 22.4276 31.7732 21.8976 34.0752 20.8366L34.6722 20.5626C35.3262 20.2606 35.6532 20.1106 36.0002 20.1106C36.3472 20.1106 36.6742 20.2606 37.3292 20.5626L37.9242 20.8366C40.2272 21.8966 41.3792 22.4266 42.1442 21.8466C42.9112 21.2646 42.7842 19.9566 42.5312 17.3396L42.4832 16.8526Z"
          fill={fillColor}
        />
        <path
          opacity={STAR_OPACITY}
          d="M33.1532 5.49345L32.8252 6.08145C32.4652 6.72745 32.2852 7.05045 32.0052 7.26345C32.0452 7.23345 32.0822 7.20145 32.1182 7.16645C31.8832 8.55246 31.9955 9.97512 32.4453 11.307C32.8951 12.6389 33.6681 13.8385 34.6953 14.7982C35.7225 15.758 36.9717 16.4482 38.331 16.8067C39.6903 17.1651 41.1173 17.1808 42.4842 16.8524L42.4642 16.6624C42.3932 15.9194 42.3572 15.5474 42.4642 15.2024C42.5712 14.8584 42.8092 14.5794 43.2862 14.0224L43.7202 13.5154C45.3972 11.5554 46.2352 10.5744 45.9422 9.63345C45.6502 8.69245 44.4202 8.41345 41.9602 7.85745L41.3242 7.71345C40.6252 7.55545 40.2752 7.47645 39.9942 7.26345C39.7142 7.05045 39.5342 6.72745 39.1742 6.08145L38.8472 5.49345C37.5802 3.22145 36.9472 2.08545 36.0002 2.08545C35.0532 2.08545 34.4202 3.22145 33.1532 5.49345Z"
          fill={fillColor}
        />
        <path
          d="M66.4832 16.8526C65.1164 17.1808 63.6895 17.1649 62.3303 16.8063C60.9711 16.4477 59.7221 15.7576 58.695 14.7979C57.668 13.8381 56.895 12.6386 56.4453 11.3068C55.9956 9.97496 55.8832 8.55241 56.1182 7.1665C56.0825 7.20102 56.0447 7.23341 56.0052 7.2635C55.7252 7.4765 55.3752 7.5555 54.6752 7.7135L54.0402 7.8575C51.5802 8.4145 50.3502 8.69251 50.0572 9.63351C49.7652 10.5736 50.6032 11.5546 52.2802 13.5156L52.7142 14.0226C53.1902 14.5796 53.4292 14.8586 53.5362 15.2026C53.6432 15.5476 53.6072 15.9196 53.5352 16.6626L53.4692 17.3396C53.2162 19.9566 53.0892 21.2646 53.8552 21.8456C54.6212 22.4276 55.7732 21.8976 58.0752 20.8366L58.6722 20.5626C59.3262 20.2606 59.6532 20.1106 60.0002 20.1106C60.3472 20.1106 60.6742 20.2606 61.3292 20.5626L61.9242 20.8366C64.2272 21.8966 65.3792 22.4266 66.1442 21.8466C66.9112 21.2646 66.7842 19.9566 66.5312 17.3396L66.4832 16.8526Z"
          fill={fillColor}
        />{' '}
        <path
          opacity={STAR_OPACITY}
          d="M57.1532 5.49345L56.8252 6.08145C56.4652 6.72745 56.2852 7.05045 56.0052 7.26345C56.0452 7.23345 56.0822 7.20145 56.1182 7.16645C55.8832 8.55246 55.9955 9.97512 56.4453 11.307C56.8951 12.6389 57.6681 13.8385 58.6953 14.7982C59.7225 15.758 60.9717 16.4482 62.331 16.8067C63.6903 17.1651 65.1173 17.1808 66.4842 16.8524L66.4642 16.6624C66.3932 15.9194 66.3572 15.5474 66.4642 15.2024C66.5712 14.8584 66.8092 14.5794 67.2862 14.0224L67.7202 13.5154C69.3972 11.5554 70.2352 10.5744 69.9422 9.63345C69.6502 8.69245 68.4202 8.41345 65.9602 7.85745L65.3242 7.71345C64.6252 7.55545 64.2752 7.47645 63.9942 7.26345C63.7142 7.05045 63.5342 6.72745 63.1742 6.08145L62.8472 5.49345C61.5802 3.22145 60.9472 2.08545 60.0002 2.08545C59.0532 2.08545 58.4202 3.22145 57.1532 5.49345Z"
          fill={fillColor}
        />
        <path
          d="M90.4832 16.8526C89.1164 17.1808 87.6895 17.1649 86.3303 16.8063C84.9711 16.4477 83.7221 15.7576 82.695 14.7979C81.668 13.8381 80.895 12.6386 80.4453 11.3068C79.9956 9.97496 79.8832 8.55241 80.1182 7.1665C80.0825 7.20102 80.0447 7.23341 80.0052 7.2635C79.7252 7.4765 79.3752 7.5555 78.6752 7.7135L78.0402 7.8575C75.5802 8.4145 74.3502 8.69251 74.0572 9.63351C73.7652 10.5736 74.6032 11.5546 76.2802 13.5156L76.7142 14.0226C77.1902 14.5796 77.4292 14.8586 77.5362 15.2026C77.6432 15.5476 77.6072 15.9196 77.5352 16.6626L77.4692 17.3396C77.2162 19.9566 77.0892 21.2646 77.8552 21.8456C78.6212 22.4276 79.7732 21.8976 82.0752 20.8366L82.6722 20.5626C83.3262 20.2606 83.6532 20.1106 84.0002 20.1106C84.3472 20.1106 84.6742 20.2606 85.3292 20.5626L85.9242 20.8366C88.2272 21.8966 89.3792 22.4266 90.1442 21.8466C90.9112 21.2646 90.7842 19.9566 90.5312 17.3396L90.4832 16.8526Z"
          fill={fillColor}
        />
        <path
          opacity={STAR_OPACITY}
          d="M81.1532 5.49345L80.8252 6.08145C80.4652 6.72745 80.2852 7.05045 80.0052 7.26345C80.0452 7.23345 80.0822 7.20145 80.1182 7.16645C79.8832 8.55246 79.9955 9.97512 80.4453 11.307C80.8951 12.6389 81.6681 13.8385 82.6953 14.7982C83.7225 15.758 84.9717 16.4482 86.331 16.8067C87.6903 17.1651 89.1173 17.1808 90.4842 16.8524L90.4642 16.6624C90.3932 15.9194 90.3572 15.5474 90.4642 15.2024C90.5712 14.8584 90.8092 14.5794 91.2862 14.0224L91.7202 13.5154C93.3972 11.5554 94.2352 10.5744 93.9422 9.63345C93.6502 8.69245 92.4202 8.41345 89.9602 7.85745L89.3242 7.71345C88.6252 7.55545 88.2752 7.47645 87.9942 7.26345C87.7142 7.05045 87.5342 6.72745 87.1742 6.08145L86.8472 5.49345C85.5802 3.22145 84.9472 2.08545 84.0002 2.08545C83.0532 2.08545 82.4202 3.22145 81.1532 5.49345Z"
          fill={fillColor}
        />
        <path
          d="M114.483 16.8526C113.116 17.1808 111.689 17.1649 110.33 16.8063C108.971 16.4477 107.722 15.7576 106.695 14.7979C105.668 13.8381 104.895 12.6386 104.445 11.3068C103.995 9.97496 103.883 8.55241 104.118 7.1665C104.082 7.20102 104.045 7.23341 104.005 7.2635C103.725 7.4765 103.375 7.5555 102.675 7.7135L102.04 7.8575C99.5802 8.4145 98.3502 8.69251 98.0572 9.63351C97.7652 10.5736 98.6032 11.5546 100.28 13.5156L100.714 14.0226C101.19 14.5796 101.429 14.8586 101.536 15.2026C101.643 15.5476 101.607 15.9196 101.535 16.6626L101.469 17.3396C101.216 19.9566 101.089 21.2646 101.855 21.8456C102.621 22.4276 103.773 21.8976 106.075 20.8366L106.672 20.5626C107.326 20.2606 107.653 20.1106 108 20.1106C108.347 20.1106 108.674 20.2606 109.329 20.5626L109.924 20.8366C112.227 21.8966 113.379 22.4266 114.144 21.8466C114.911 21.2646 114.784 19.9566 114.531 17.3396L114.483 16.8526Z"
          fill={fillColor}
        />
        <path
          opacity={STAR_OPACITY}
          d="M105.153 5.49345L104.825 6.08145C104.465 6.72745 104.285 7.05045 104.005 7.26345C104.045 7.23345 104.082 7.20145 104.118 7.16645C103.883 8.55246 103.995 9.97512 104.445 11.307C104.895 12.6389 105.668 13.8385 106.695 14.7982C107.722 15.758 108.971 16.4482 110.331 16.8067C111.69 17.1651 113.117 17.1808 114.484 16.8524L114.464 16.6624C114.393 15.9194 114.357 15.5474 114.464 15.2024C114.571 14.8584 114.809 14.5794 115.286 14.0224L115.72 13.5154C117.397 11.5554 118.235 10.5744 117.942 9.63345C117.65 8.69245 116.42 8.41345 113.96 7.85745L113.324 7.71345C112.625 7.55545 112.275 7.47645 111.994 7.26345C111.714 7.05045 111.534 6.72745 111.174 6.08145L110.847 5.49345C109.58 3.22145 108.947 2.08545 108 2.08545C107.053 2.08545 106.42 3.22145 105.153 5.49345Z"
          fill={fillColor}
        />
      </svg>
    </div>
  )
}

export default FiveStarIcon
